<template>
  <div class="customer-authenticate mt-32">
    <template v-if="isReady">
      <div class="ds-wrapper mt-3 md:mt-10 lg:mt-10 pt-4 md:pt-6 lg:pt-8" v-if="isPaymentRequestActive">
        <div class="rounded-3xl bg-container-40 px-6 sm:px-8 pb-8 sm:pb-10 text-center sm:w-3/5 md:w-4/12 mx-auto">
          <p class="py-6 para-def-light">Your details are secured with a one-time code</p>
          <p class="text-primary mb-10">Tap button below to send code to <br />
          {{ mobile }}</p>
          <vs-button color="primary" size="large" class="w-full max-w-400" @click="sendCodeToCustomer">Send code</vs-button>
        </div>
      </div>
      <template v-else-if="showCompletedComponent">
        <completed-page></completed-page>
      </template>
      <template v-else>
        <not-found></not-found>
      </template>
    </template>
  </div>
</template>

<script>
  import { clearLocalStorage } from "../../helper/localStorage.js";
  import { mapActions } from "vuex";
  import CompletedPage from "./CompletedPage.vue";
  import NotFound from "./NotFound.vue";

  export default {
    components: { CompletedPage, NotFound },

    data() {
      return {
        isMobileOrEmail: "mobile",
        mobile: "",
        email: "",
        prId: this.$route.params.prId,
        isPaymentRequestActive: true,
        showCompletedComponent: false,
        isReady: false,
      };
    },

    methods: {
      ...mapActions("paymentRequest", [
        "fetchPaymentRequestDetailByPRId",
        "sendCode",
      ]),

      async getPaymentRequestDetail() {
        const prId = this.prId;
        this.$vs.loading();
        await this.fetchPaymentRequestDetailByPRId(prId).then((res) => {
          let prDetails = res.data.data;
          this.$vs.loading.close();
          
          if (prDetails && prDetails.paymentStatus == "Completed") {
            this.isPaymentRequestActive = false;
            this.showCompletedComponent = true;
            return;
          }

          if (prDetails && !["Active", "Extended"].includes(prDetails.paymentStatus)) {
            this.isPaymentRequestActive = false;
            return;
          }

          this.mobile = prDetails.payeePhone;
          this.email = prDetails.payeeEmail;
        }).catch(ex => {
          this.isPaymentRequestActive = false;
          this.$vs.loading.close();
        });

        this.isReady = true;
      },

      sendCodeToCustomer() {
        let {isMobileOrEmail, prId } = this;
        this.$vs.loading();
        this.sendCode({ isMobileOrEmail, prId }).then((res) => {
          localStorage.setItem("isMobileOrEmail", this.isMobileOrEmail);
          localStorage.setItem("mobileOrEmail", this.mobile);
          this.$vs.loading.close();
          this.$router.push({ name: "VerifyCode", params: { prId } });
        }).catch(ex => {
          this.$vs.loading.close();
          this.showToastMessage("Error", "We are unable to process your request at this time. Please try again later.", "error");
        });
      },
    },

    created() {
      clearLocalStorage();
      this.getPaymentRequestDetail();
    },
  };
</script>